import React from "react";
import { useLastLogin } from "../hooks/storeHooks";
import { renderDate, getHourMinute, getAmPm } from "../utils/helper";

const LastLogin = () => {
  const LastLogin = useLastLogin();
  return (
    <div className="card-body">
      <div className="d-flex jsb loginDetails">
        <div className="last_login">
          <div className="lbl mb-3">Last Login Info </div>
          <div className="val">
            <span className="Logintime">{(LastLogin && LastLogin?.createdAt) ? `${getHourMinute(LastLogin.createdAt)} ${getAmPm(LastLogin.createdAt)}` : ""}</span> -
            <span className="Logindate">
              {(LastLogin && LastLogin?.createdAt)?renderDate(LastLogin?.createdAt):null}
            </span>
          </div>
        </div>
        <div className="ipadrs">
          <div className="lbl mb-3">IP Address</div>
          <div className="val">
            <span className="ipaddress">{LastLogin?.ip}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LastLogin;
